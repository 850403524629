<!-- Topbar Start -->
<div class="navbar-custom" [class]="cssClasses">
    <div class="container-fluid">
        <!-- LOGO -->
        <a routerLink="/" class="topnav-logo" *ngIf="!hideLogo && topbarDark">
            <span class="topnav-logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="16">
            </span>
            <span class="topnav-logo-sm">
                <img src="assets/images/logo_sm.png" alt="" height="16">
            </span>
        </a>

        <!-- LOGO -->
        <a routerLink="/" class="topnav-logo" *ngIf="!hideLogo && !topbarDark">
            <span class="topnav-logo-lg">
                <img src="assets/images/logo-dark.png" alt="" height="16">
            </span>
            <span class="topnav-logo-sm">
                <img src="assets/images/logo_sm_dark.png" alt="" height="16">
            </span>
        </a>
        
        <ul class="list-unstyled topbar-menu float-end mb-0">
            
            <li class="dropdown notification-list d-lg-none" ngbDropdown>
                <a ngbDropdownToggle class="nav-link dropdown-toggle arrow-none" id="mobileDropdown" href="javascript: void(0);" aria-expanded="false">
                    <i class="dripicons-search noti-icon"></i>
                </a>
                <div ngbDropdownMenu aria-labelledby="mobileDropdown" class="dropdown-menu-animated dropdown-lg p-0">
                    <form ngbDropdownItem class="p-3">
                        <input type="text" class="form-control" placeholder="Rechercher ..." aria-label="Recipient's username">
                    </form>
                </div>
            </li>
            
            <li class="dropdown notification-list" ngbDropdown>
                <a ngbDropdownToggle class="nav-link nav-user arrow-none me-0" id="profileMenuDropdown" href="javascript:void(0)" aria-expanded="false">
                    <span class="account-user-avatar">
                        <img [src]="loggedInUser.avatar" alt="user-image" class="rounded-circle">
                    </span>
                    <span>
                        <span class="account-user-name">{{loggedInUser.name}}</span>
                    <span class="account-position">{{loggedInUser.title}}</span>
                    </span>
                </a>
                <div ngbDropdownMenu aria-labelledby="profileMenuDropdown" class="dropdown-menu-end dropdown-menu-animated topbar-dropdown-menu profile-dropdown">
                    <!-- item -->
                    <div ngbDropdownItem class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Bienvenue !</h6>
                    </div>
                    <!-- item -->
                    <a *ngFor="let option of profileOptions;" class="notify-item" ngbDropdownItem>
                        <i class="{{option.icon}} me-1"></i>
                        <span [routerLink]="[option.redirectTo]" style="cursor: pointer;">{{option.label}}</span>
                    </a>
                </div>
            </li>
        </ul>

        <!-- mobile menu toggle -->
        <button class="button-menu-mobile open-left d-none d-md-block" (click)="toggleSidebarWidth()" *ngIf="layoutType==='vertical'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>
        <button class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)" *ngIf="layoutType==='vertical'">
            <i class="mdi mdi-menu open-left-icon"></i>
        </button>


        <a class="navbar-toggle open-left" [ngClass]="{'open': topnavCollapsed}" (click)="toggleMobileMenu($event)" *ngIf="layoutType==='horizontal'">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>

        <a class="button-menu-mobile open-left disable-btn" (click)="toggleMobileMenu($event)" *ngIf="layoutType==='detached'">
            <div class="lines">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </a>

        <div class="app-search dropdown d-none d-lg-block" ngbDropdown container="body" placement="bottom-start">
            <!-- <form *ngIf="succursale.id">
                <div class="input-group">
                    <h4>{{ succursale.nom }}</h4>
                </div>
            </form> -->
            <!-- <form>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Rechercher..." id="topSearch" ngbDropdownToggle>
                    <span class="mdi mdi-magnify search-icon"></span>
                    <div class="input-group-append">
                        <button class="btn btn-secondary" type="submit">Rechercher</button>
                    </div>
                </div>
            </form> -->
            
        </div>
       

    </div>

</div>
<!-- Topbar End -->