import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { TokenDetail, User } from '../models/auth.models';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User | null = null;
    tokenDetail: TokenDetail | null = null;
    token: string | null = null;
    authPrefix: string = 'api/auth';

    constructor (private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User | null {
        if (!this.user) {
            this.user = JSON.parse(sessionStorage.getItem('currentUser')!);
        }
        return this.user;
    }

    public currentToken(): string | null {
        if (!this.token) {
            this.token = sessionStorage.getItem('currentToken');
        }
        return this.token;
    }

    /**
     * Performs the login auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string): any {
        //console.log("login :", email, password);
        return this.http.post<any>(`/api/login`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                //console.log("user :", user);
                
                if (user && user.token) {
                    this.user = user;
                    // store user details and jwt in session
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                }
                return user;
            }));
    }



    /**
     * Performs the signup auth
     * @param name name of user
     * @param email email of user
     * @param password password of user
     */
    signup(name: string, email: string, password: string): any {
        return this.http.post<any>(`/api/signup`, { name, email, password })
            .pipe(map(user => user));

    }



    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        sessionStorage.removeItem('currentUser');
        this.user = null;
    }


    /* CUSTOM */
    loginJ(username: string, password: string): any {
        
        this.removeKeys();
        return this.http.post<any>(`${environment.apiUrl}/${this.authPrefix}/login`, { username, password })
            .pipe(map(data => this.storeKeys(data)), );
            //.pipe(map(token => {
                // login successful if there's a jwt token in the response
                //console.log("token :", token);
                //this.token = token;
                //sessionStorage.setItem('currentToken', JSON.stringify(token));
    
                //return token;
            //}));
    }

    public storeKeys(data: any) {
        //localStorage.setItem(this.ACCESS, data.accessToken);
        //console.log("data :", data);
        if (data && data.access_token) {
            this.tokenDetail = data;
            // store token details and jwt in session
            sessionStorage.setItem('currentTokenDetail', JSON.stringify(this.tokenDetail));
            sessionStorage.setItem('currentToken', this.tokenDetail?.access_token!);
        }
        return this.tokenDetail;
        //sessionStorage.setItem('currentToken', JSON.stringify(data));
      }

    logoutJ(): void {
        // remove user from session storage to log user out
        this.http.get<any>(`${environment.apiUrl}/${this.authPrefix}/logout`).subscribe(
            (data) => {

                //console.log("data : ", data);

                /*
                sessionStorage.removeItem('currentTokenDetail');
                sessionStorage.removeItem('currentToken');
                sessionStorage.removeItem('currentUser');
                */
            },
            (error) =>{
                //console.log("error :", error);
                if(error.status == 200){
                    this.removeKeys();
                    
                }
                this.removeKeys();
            }
        );
        
        this.user = null;
        
    }

    removeKeys(){
        if(sessionStorage.getItem('currentTokenDetail')){
            sessionStorage.removeItem('currentTokenDetail');
        }
        if(sessionStorage.getItem('currentToken')){
            sessionStorage.removeItem('currentToken');
        }
        if(sessionStorage.getItem('currentUser')){
            sessionStorage.removeItem('currentUser');
        }
        if(sessionStorage.getItem('succursale_id')){
            sessionStorage.removeItem('succursale_id');
        }
        if(sessionStorage.getItem('succursale')){
            sessionStorage.removeItem('succursale');
        }
        if(sessionStorage.getItem('role')){
            sessionStorage.removeItem('role');
        }
        if(this.token){
            this.token = null;
        }
    }

    userInfo(): any {
        
        return this.http.get<any>(`${environment.apiUrl}/${this.authPrefix}/userinfo`)
            .pipe(map(data => {
                if(data) {
                    console.log("data :", data);
                    this.user = data;
                    //this.user!.avatar = "assets/images/users/avatar-5.jpg";
                    this.user!.avatar = "assets/id/1.png";
                    sessionStorage.setItem('currentUser', JSON.stringify(this.user));
                }
    
                return this.user;
            }));
    }
    public getInfo() {
        return this.http.get(`${environment.apiUrl}/${this.authPrefix}/user/currentUserRoles`);
      }
    

    getUserByEmail(email: any): any { 
        return this.http.get<any>(`${environment.apiUrl}/${this.authPrefix}/getUsers/email/${email}`);
    }
    sendResetPasswordLink(id: any): any { 
        return this.http.put<any>(`${environment.apiUrl}/${this.authPrefix}/user/sendActionRequiredMail/${id}`, {
            "actions": [
                "UPDATE_PASSWORD"
            ]
        });
    }

    activateAccount(key: string){
        return this.http.get(`${environment.apiUrl}/api/mail/activateAccount?key=${key}`);
    }

    verifyPasswordResetLink(key: string){
        return this.http.get(`${environment.apiUrl}/api/mail/verifyPasswordResetLink?key=${key}`);
    }

}

